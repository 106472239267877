/*
 * This is the 'source' that the vendor CSS lib will build from. No more manual generation.
 */
// bootstrap
@import url('../node_modules/bootstrap/dist/css/bootstrap.min.css');
// bootstrap theme
@import url('../node_modules/bootstrap/dist/css/bootstrap-theme.min.css');
// font awesome
@import url('../node_modules/font-awesome/css/font-awesome.min.css');
// angular-block-ui
@import url('../node_modules/angular-block-ui/dist/angular-block-ui.min.css');
// jquery-ui datepicker
@import './assets/jq-ui-datepicker';
// text-angular
@import url('../node_modules/textangular/dist/textAngular.css');
// toastr
@import '../node_modules/toastr/toastr';
// spectrum-colorpicker
@import url('../node_modules/spectrum-colorpicker/spectrum.css');

// app specific customizations to styling
@import url('./css/styles.css');

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

#content1 {
    min-height: 390px;
}

#content2 {
    min-height: 440px;
}

#footer1 {
    width: 100%;
    height: 100px;
    bottom: 0;
    left: 0;
}

#footer2 {
    width: 100%;
    height: 40px;
    bottom: 0;
    left: 0;
}