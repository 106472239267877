@charset "UTF-8";
@font-face {
  font-family: creditcard-icons;
  src: url(./fonts/creditcard-icons.eot);
  src: url(./fonts/creditcard-icons.eot?#iefix) format("embedded-opentype"),
    url(./fonts/creditcard-icons.ttf) format("truetype"),
    url(./fonts/creditcard-icons.svg#creditcard-icons) format("svg"),
    url(./fonts/creditcard-icons.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
[class*=" icon-"]:before,
[class^="icon-"]:before {
  font-family: creditcard-icons !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-amex-card:before {
  content: "a";
}
.icon-master-card:before {
  content: "b";
}
.icon-cvc-code:before {
  content: "c";
}
.icon-credit-card:before {
  content: "d";
}
.icon-visa-card:before,
.ui.form .input.visa i.icon:before {
  content: "e";
}
.ui.form .input.left input[type="email"]:active,
.ui.form .input.left input[type="date"]:active,
.ui.form .input.left input[type="password"]:active,
.ui.form .input.left input[type="number"]:active,
.ui.form .input.left input[type="url"]:active,
.ui.form .input.left input[type="tel"]:active,
.ui.form .input.left textarea:active,
.ui.form input[type="text"]:active,
.ui.form input[type="credit card"]:active,
.ui.form input[type="cvc"]:active,
.ui.form input[type="month"]:active {
  outline: #3498db solid 1px;
}
.ui.form .field.error .input.left input {
  background-color: snow;
  color: #d95c5c;
  border-radius: 0.4em 0.3125em 0.3125em 0.4em;
  border: 1px solid #d95c5c;
  box-shadow: 0.3em 0 0 0 #d95c5c inset;
}
.ui.form .input.left input[type="text"]:focus,
.ui.form .input.left input[type="credit card"]:focus,
.ui.form .input.left input[type="cvc"]:focus,
.ui.form .input.left input[type="month"]:focus,
.ui.form .input.left input[type="email"]:focus,
.ui.form .input.left input[type="date"]:focus,
.ui.form .input.left input[type="password"]:focus,
.ui.form .input.left input[type="number"]:focus,
.ui.form .input.left input[type="url"]:focus,
.ui.form .input.left input[type="tel"]:focus,
.ui.form .input.left textarea:focus {
  border-left: 1px solid #3498db;
  box-shadow: 0.3em 0 0 0 #3498db inset;
  border-bottom-left-radius: 0.4em;
  border-top-left-radius: 0.4em;
}
.ui.form .field.error .input.right input {
  background-color: snow;
  color: #d95c5c;
  border-radius: 0.3125em 0.4em 0.4em 0.3125em;
  border: 1px solid #d95c5c;
  box-shadow: -0.3em 0 0 0 #d95c5c inset;
}
.ui.form .input.right input[type="text"]:focus,
.ui.form .input.right input[type="credit card"]:focus,
.ui.form .input.right input[type="cvc"]:focus,
.ui.form .input.right input[type="month"]:focus,
.ui.form .input.right input[type="email"]:focus,
.ui.form .input.right input[type="date"]:focus,
.ui.form .input.right input[type="password"]:focus,
.ui.form .input.right input[type="number"]:focus,
.ui.form .input.right input[type="url"]:focus,
.ui.form .input.right input[type="tel"]:focus,
.ui.form .input.right textarea:focus {
  border-radius: 0.3125em 0.4em 0.4em 0.3125em;
  border-right: 1px solid #3498db;
  box-shadow: -0.3em 0 0 0 #3498db inset;
}
.ui.form .input input[type="credit card"] {
  letter-spacing: 0.05em;
}
.ui.form .input.master i.icon:before {
  content: "b";
}
.ui.form .input.amex i.icon:before {
  content: "a";
}
i.basic.icon.calendar:before {
  content: "ðŸ“…";
}
i.icon.calendar.empty:before {
  content: "\f133";
}
i.icon.calendar:before {
  content: "\f073";
}
body {
  padding-top: 0;
}
.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;
}
.navbar-inverse {
  border-color: #fff;
  border-bottom-width: 10px;
  border-top-width: 12px;
  height: 68px;
  background-image: -webkit-linear-gradient(top, #2e2e2e 0, #2e2e2e 100%);
  background-image: -o-linear-gradient(top, #2e2e2e 0, #2e2e2e 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#2e2e2e),
    to(#00468c)
  );
  background-image: linear-gradient(to bottom, #2e2e2e 0, #2e2e2e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3c3c3c', endColorstr='#ff222222', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  background-repeat: repeat-x;
  border-radius: 0;
}
.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-brand:focus,
.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-nav > li > a {
  color: #fff;
}
.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-color: #2E2E2E;
}
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .active > a:hover {
  background-color: #b92833;
  background-image: linear-gradient(to bottom, #b92833 0, #b92833 100%);
}
@media only screen and (max-width: 767px) {
  .navbar-inverse {
    height: auto;
  }
  .dropdown-menu{ position: inherit;}
}
.topNav {
  margin-top: -22px;
  margin-right: 30px;
  position: relative;
  z-index: 101;
}
.top {
  padding: 0 25px 10px;
  text-align: center;
}
.text-warning {
  color: #ff7103;
}
.textLeft {
  text-align: left;
}
.withCancel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}
.textRight {
  text-align: right;
}
.account_tiles a,
.add_search,
.charity_menu a,
.circleNumber,
.home_img h2,
.textCenter {
  text-align: center;
}
.columnBorderNotes {
  padding: 10px;
  border: 1px solid #ccc;
  border-image: none;
  margin-top: 10px;
  background-color: #fff;
}
.columnRuleRight {
  padding-right: 10px;
  border-right-color: #ccc;
  border-right-width: 1px;
  border-right-style: solid;
}
.none {
  float: none;
}
input[type="checkbox"].big {
  padding: 10px;
  transform: scale(2);
  -webkit-transform: scale(2);
  -o-transform: scale(2);
  -moz-transform: scale(2);
}
.circleNumber {
  font-size: 1em;
  background-color: #004b8d;
  color: #fff;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  height: 40px;
  width: 150px;
  line-height: 40px;
  text-transform: uppercase;
  padding: 4px;
}
.checkbox {
  margin-left: 0;
}
.checkbox label,
.radio label {
  padding-left: 0;
}
.imageColumn {
  margin-left: -40px;
}
.CFCSideImage {
  width: 270px;
  height: auto;
}
.table-scroll {
  height: 300px;
  overflow: scroll;
  margin-top: 20px;
}
.text-grey {
  color: #8e8e8e;
}
.text-yellow {
  color: #ffdd31;
}
.home_img {
  margin-top: 10px;
}
.home_img .form-control {
  height: 44px;
}
.home_img img {
  min-height: 480px;
  width: 100%;
}
.account_tiles {
  list-style: none;
  padding: 0;
}
.account_tiles li {
  display: table;
  height: 250px;
  padding: 10px;
}
.account_tiles a {
  color: #fff;
  float: left;
  font-size: 19px;
  font-weight: 700;
  height: 230px;
  padding: 50px 30px 0;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 100%;
}
.color1 a {
  background: #006567;
}
.color2 a {
  background: #653366;
}
.color3 a {
  background: #ae192f;
}
.color4 a {
  background: #02377d;
}
.abs_account {
  display: block;
  clear: both;
}
.abs_tabs li {
  float: left;
  padding: 10px;
}
.abs_tabs li.active a {
  background: #3636f1;
}
.abs_account h3 {
  float: left;
  color: #404040;
}
a.edit {
  margin: 25px 10px 0;
  float: left;
  text-decoration: none;
  cursor: pointer;
}
.head_logo.container a,
.head_logo.container a:focus,
.head_logo.container a:hover,
.table > tbody > tr > td a {
  text-decoration: none !important;
}
.accnt .form-group {
  clear: both;
  margin: 5px 0;
}
.table.table.table-hover th {
  color: #000;
  background-color: #999;
  border-right: 1px solid #d7d7d7;
}
.table-responsive {
  clear: both;
}
.abs_about {
  display: block;
  float: left;
}
a {
  color: #016dda;
}
.head_logo {
  padding: 10px 15px;
}
.head_logo .logo {
  width: 70px;
  margin-right: 40px;
  float: left;
}
.head_logo span {
  font-size: 28px;
  color: #01367a;
}
.account_tiles i {
  bottom: 55px;
  color: #222;
  font-size: 36px;
  left: 0;
  position: absolute;
  right: 0;
}
.account_tiles .fa-phone,
.account_tiles .fa-question {
  background-color: #222;
  color: #fff;
  margin: auto;
  padding: 2px;
}
.account_tiles .fa-question {
  border-radius: 50%;
  width: 40px;
}
.account_tiles .fa-phone {
  border-radius: 7px;
  font-size: 32px;
  width: 38px;
}
.no_pad {
  padding: 0 !important;
}
.home_img h2 {
  color: #053685;
  font-size: 29px;
  margin: 0;
}
.columnBorder {
  border: 1px solid #ccc;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding-top: 5px;
}
.create_acc,
.sign_in {
  border: 0 solid #1b95e0;
  padding: 14px 10px;
}
.home_img label {
  color: #6a6a6a;
  font-weight: 400;
  margin-bottom: 0;
}
.forget {
  color: #2192d4;
  float: right;
}
.abs_footer a,
.charity_menu a,
.padd_tb_20,
.privacy_tearm {
  float: left;
  width: 100%;
}
.sign_in {
  background: #1b95e0;
}
.create_acc {
  background: #e4e4e4;
  color: #616161;
}
.home_img .user_text {
  background-color: #fff;
  color: #676767;
  font-size: 14px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: -10px;
  width: 70px;
}
.charity_table,
.copyright {
  font-size: 13px;
}
.user_border {
  border-top: 1px solid #ccc;
  margin-top: 22px;
  padding-top: 22px;
  position: relative;
}
.home_img .user_border p {
  color: #656565;
  text-align: center;
}
.home_img .user_border p a {
  color: #1b99e6;
}
.abs_footer a,
.abs_footer label,
.copyright {
  color: #040404;
}
.columnBorderHomePage {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 3px 0 #333;
  left: 0;
  right: 0;
  top: 50%;
  position: inherit;
  transform: translateY(2%) !important;
  padding: 8px;
}
.abs_footer {
  background-color: #e4e4e4;
  font-size: 16px;
  margin-top: 30px;
  padding: 10px 0;
}
.privacy_tearm {
  padding-top: 25px;
}
.charity_menu a {
  background-color: #e4e4e4;
  border: 1px solid #d2d2d2;
  color: #2f2f2f;
  margin-bottom: 1px;
  padding: 10px 15px;
  text-decoration: none;
}
.charity_menu a:hover {
  background-color: #fff;
}
.charity_content h2 {
  color: #333;
  font-weight: 700;
  margin: 0 0 10px;
}
.charity_center p {
  color: #333;
  font-size: 15px;
}
.charity_btn,
.charity_btn:focus,
.charity_btn:hover {
  background-color: #1b95e0;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  color: #fff;
  padding: 12px 15px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.charity_table a {
  color: #0064ca;
  text-decoration: underline;
}
.add_search {
  padding: 5px 0 15px;
}
.charity_table,
.charity_table tbody tr td:first-child {
  text-align: left;
}
.add_search .charity_btn:first-child {
  margin-right: 7%;
}
.charity_btn .fa-plus {
  background-color: #222;
  border-radius: 50%;
  margin-right: 20px;
  padding: 4px;
}
.charity_btn .fa-search {
  color: #222;
  font-size: 21px;
  margin-right: 20px;
}
.charity_table thead th {
  background-color: #02327a;
  border: 1px solid;
  color: #fff;
  font-weight: 400;
  padding: 8px;
}
.charity_table {
  color: #353535;
  font-weight: 700;
  width: 100%;
}
.charity_table tbody tr td {
  padding: 3px;
}
.charity_table tr:nth-child(2n + 1) {
  background-color: #e5e5e5;
}
.padd_tb_20 {
  padding: 20px 0;
}
.aboutus .tab-content .tab-pane {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: -moz-use-text-color #ddd #ddd;
  border-image: none;
  border-style: none solid solid;
  border-width: 0 1px 1px;
  padding: 5px;
}
@media (min-width: 320px) and (max-width: 640px) {
  .color1 {
    margin-top: 20px;
  }
}
.LeftMenuItem {
  background-color: #dcdcdc;
  border: 1px solid #dcdcdc;
  display: inline-block;
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  min-height: 50px;
  width: 97%;
  padding-left: 4px;
  padding-top: 13px;
  margin-bottom: 2px;
  margin-top: 2px;
  margin-left: 2px;
  color: #5f5f5f;
  text-align: center;
}
.btn-active,
.btn-inactive {
  padding-left: 10px;
  font-weight: 700;
}
.btn-active,
.btn-inactive,
.opm {
  text-align: left;
}
.LeftMenuItemSelected,
a.LeftMenuItem:hover {
  background-color: #fff !important;
}
.btn-primary {
  color: #fff;
  background-color: #00468c;
  background-image: -webkit-linear-gradient(top, #00468c 0, #00468c 100%);
  background-image: -o-linear-gradient(top, #337ab7 0, #00468c 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#00468c),
    to(#00468c)
  );
  background-image: linear-gradient(to bottom, #00468c 100%, #00468c 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00468c', endColorstr='#00468c', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  background-repeat: repeat-x;
  border-color: #00468c;
}
.btn-primary.active,
.btn-primary.focus,
.btn-primary.hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  color: #fff;
  background-color: #016dda;
  border-color: #016dda;
  background-image: linear-gradient(to bottom, #016dda 100%, #016dda 100%);
}
.btn-active,
.btn-active:hover {
  color: #fff;
  background-color: #b92833;
  border-color: #b92833;
}
.btn-inactive {
  color: #000;
  background-color: #c7c7c7;
  border-color: #c7c7c7;
}
.btn-inactive:hover {
  color: #fff;
  background-color: #00468c;
  border-color: #00468c;
}
.right {
  float: right;
}
.pendingRequests a {
  color: #016dda;
  text-decoration: none !important;
}
.pendingRequests a:hover {
  color: #016dda;
  text-decoration: underline !important;
}
.pendingRequests a:active {
  color: #016dda;
  text-decoration: none !important;
}
.vh {
  position: absolute;
  height: 1px;
  overflow: hidden;
}
.footer {
  margin-top: 25px;
  margin-bottom: 20px;
  color: #363636;
  background-color: #fff;
  border-top: 1px solid #666;
  padding: 20px 0;
  font-size: 11px;
}
.footer a {
  text-decoration: none;
  color: #363636;
}
.footer a:hover {
  text-decoration: underline;
  color: #363636;
}
.memberSortNumber {
  width: 55px;
}
.flagBack {
  background-image: url(../images/flag.jpg);
  background-repeat: no-repeat;
  height: 100%;
  right: 0;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.275);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.275);
  -webkit-border-radius: 0 10px 10px 0;
  border-radius: 0 10px 10px 0;
}
a:focus,
a:hover {
  color: #016dda;
  text-decoration: underline;
}
.navtop a,
a:hover .headerOPM {
  text-decoration: none;
}
.headerOPM {
  font-size: 11px;
  color: #959595;
  font-family: Droid-serif, serif;
  font-style: italic;
  margin: 8px 0 0 6px;
  display: inline-block;
}
.question-tooltip:hover {
  cursor: pointer;
}
.homePageMsgH2,
.opm h2,
.opm h4,
.opm-font {
  font-family: "Droid Serif", Georgia, "Times New Roman", Times, serif;
}
.navtop {
  font-size: 0.85em;
  color: #363636;
  margin: 10px 0 25px;
}
.navtop a {
  color: #363636;
}
.navtop a:hover {
  text-decoration: underline;
  color: #363636;
}
.logo {
  margin-top: -35px;
  margin-left: 0;
  width: 175px;
}
.homePageMsgH2 {
  color: #00468c;
  margin-top: 5px;
}
.homeIconBox {
  width: 130px;
  height: 130px;
  display: block;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  color: #fff;
  border-radius: 5px;
  moz-border-radius: 5px;
  webkit-border-radius: 5px;
  float: left;
  vertical-align: middle;
  padding-top: 10px;
  margin: 10px;
}
.homeIconBox a {
  text-decoration: none;
}
.opm a:focus,
.opm a:hover,
.opm-lead a {
  text-decoration: underline;
}
.charityLogo {
  max-height: 175px;
  max-width: 250px;
  margin-bottom: 0;
  padding-bottom: 5px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .navbar-nav > li > a {
    padding-top: 10px;
  }
}
.opm {
  padding: 0 25px 10px;
}
.opm h2 {
  color: #00468c;
}
.opm h4 {
  color: #363636;
}
.opm a,
.opm a:active,
.opm a:focus,
.opm a:hover {
  color: #00468c;
}
.numberCircle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #00468c;
  color: #fff;
  text-align: center;
  font-size: 30px;
}
.opm-font h2 {
  color: #00468c;
  font-size: 18px;
}
.opm-font h4 {
  color: #363636;
}
.custom-header-width {
  min-width: 190px;
}
table.opm-table.table-hover td {
  border-width: 1px;
  border-style: solid;
  border-color: #d7d7d7;
  border-image: initial;
}
.table.opm-table > tbody > tr > th {
  padding: 6px 3px;
}
.table.opm-table > tbody > tr > td,
.table.opm-table > tfoot > tr > td,
.table.opm-table > tfoot > tr > th,
.table.opm-table > thead > tr > td {
  padding: 8px;
}
footer {
  clear: both;
  overflow: auto;
  background-image: url(../images/footer_TopBar.gif);
  background-repeat: repeat-x;
  background-position: 0 0;
  font-size: 70%;
  background-color: #2e2e2e;
}
.Sitemap_Container {
  margin: 0 auto;
  width: 1000px;
  clear: both;
  overflow: auto;
  color: #b7b7b7;
  position: relative;
  height: 785px;
  background-image: url(../images/footer_TopBarShadow.gif);
  /*
    re-adding the background-repeat here makes it consistent with the mock up
    we should probably just remove the background- defintions from this class and
    the `footer` definition above, which would make it look clean, but it was
    apparently requested by OPM quite specifically, even though the bg imagery
    doesn't line up with anything in particular
  */
  background-repeat: no-repeat;
  background-position: center 161px;
}
.Sitemap_Container .opm {
  position: absolute;
  left: 0;
  width: 450px;
  height: 145px;
  overflow: auto;
  padding: 45px 0 0 161px;
  margin: 0 0 0 10px;
  font-size: 1.1em;
  line-height: 1.7em;
  background-image: url(../images/footer_Seal.gif);
  background-position: 0 17px;
  background-repeat: no-repeat;
}
.GlobalLinksLeft,
.GlobalLinksMiddle,
.GlobalLinksRight {
  width: 147px;
  overflow: auto;
  padding: 46px 0 0;
  margin: 0 0 0 20px;
  font-size: 1.1em;
  line-height: 1.7em;
  list-style: none;
  position: absolute;
}
.GlobalLinksLeft a,
.GlobalLinksMiddle a,
.GlobalLinksRight a,
.Sitemap_Container .opm a {
  color: #b7b7b7;
}
.GlobalLinksLeft {
  left: 480px;
}
.GlobalLinksMiddle {
  left: 640px;
}
.GlobalLinksRight {
  left: 800px;
}
#Sitemap,
#Sitemap li,
#Sitemap li ul,
#Sitemap li ul li {
  padding: 0;
  margin: 0;
}
#Sitemap {
  margin-top: 161px;
}
#Sitemap li {
  width: 137px;
  overflow: auto;
  padding: 0;
  margin: 0 0 0 20px;
  font-size: 1.1em;
  line-height: 1.7em;
  list-style: none;
}
#Sitemap li.about-us {
  position: absolute;
  left: 0;
}
#Sitemap li.news {
  position: absolute;
  left: 800px;
}
#Sitemap li.investigations {
  position: absolute;
  top: 431px;
  left: 480px;
}
#Sitemap li.policy {
  position: absolute;
  left: 160px;
}
#Sitemap li.insurance {
  position: absolute;
  left: 320px;
}
#Sitemap li.retirement {
  position: absolute;
  left: 480px;
}
#Sitemap li.services {
  position: absolute;
  left: 640px;
}
#Sitemap li a,
.Sitemap_Container h3 {
  font-family: Tahoma, Geneva, sans-serif;
  color: #fff;
  font-weight: 700;
  margin: 2em 0 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  display: block;
}
.Sitemap_Container h3 {
  font-size: 1.1em;
  margin: 0;
  padding-bottom: 0;
}
#Sitemap li ul li a,
.Sitemap_Container p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.9em;
  color: #b7b7b7;
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
  display: inline;
}
h2,
h3 {
  font-family: "Droid Serif", Georgia, "Times New Roman", Times, serif;
}
#Sitemap a:hover {
  text-decoration: underline;
}
.Sitemap_Container p {
  display: block;
  padding-bottom: 0;
  font-size: 1em;
}
h3 {
  color: #363636;
}
h2 {
  color: #363636;
  margin-top: 5px;
}
a {
  color: #046792;
  text-decoration: none;
}
ul.sitemap > li {
  list-style: disc;
  margin-left: 40px;
}
ul.sitemap > li ul > li {
  list-style: circle;
  margin-left: 40px;
}
ul.sitemap > li ul > li > ul li {
  list-style: square;
  margin-left: 40px;
}
.margin-top-45 {
  margin-top: 45px;
}
.appsteps.pagination {
  border-radius: 0;
  margin-top: 0;
}
.appsteps.pagination>li>a, .appsteps.pagination>li>span {
  border: none;
  padding: 5px 5px;
}
.appsteps.pagination>li:first-child>a, .appsteps.pagination>li:first-child>span {
  border-radius: 0; 
}
.appsteps.pagination>li:last-child>a, .appsteps.pagination>li:last-child>span {
  border-radius: 0; 
}
.mostlyOpaque {
  background-color: rgba(0, 0, 0, 0.45);
}
.mostlyOpaqueErrorClear {
  margin-top: -1rem;
  margin-right: -1rem;
}
.spinner-mask,
.spinner-mask-error {
  display: block;
  text-align: center;
  padding: 2rem;
  min-height: 12rem;
  font-size: 2rem;
  position: absolute;
  margin-right: 5%;
  margin-left: 2.5%;
  width: 90%;
  height: 90%;
  top: 20%;
}
.spinner-mask-error {
  cursor: pointer;
}
.textangular-text-editor {
  max-height: 30rem;
  overflow-y: auto;
}
.radio input[type=radio], .checkbox input[type=checkbox] {
  margin-left: initial;
  position: inherit;
}
.cat-codes {
  column-count: 2;
  column-gap: 10px;
}
/*customized breadcrumb style*/
.breadcrumb {
  background-color: inherit;
  margin-bottom: 0px;
}
.breadcrumb>li+li:before {
  content:">\00a0";
}
.breadcrumb a,
.breadcrumb span,
.text-black {
  color: black;
}
.app-label-h4 {
  margin-top:0;
  text-transform: uppercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.select-year-bgColor {
  color: #000;
}
.btn-default-step {  
  background-color: #fff;
  background-image: -webkit-linear-gradient(top,#fff,#fff);
}
.formDivider {
  font-family: 'Droid Serif', Georgia, "Times New Roman", Times, serif;
  color: #363636;
  font-size: 1.1em;
  color: #333;
  margin: 0px 0px 10px 0px;
  padding: 0px 0px 1.0em 0px;
  border-bottom: 1px #e1e1e1 solid;
  display: block;
  padding-bottom: 10px;
}
.form-group-label{
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}
.input-file-border{
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-left: 5px;
}
.lead {
  font-family: 'Droid Serif', Georgia, "Times New Roman", Times, serif;
}
.form-group-steps {
  margin-bottom: 15px;
}
.formIndent-steps {
  margin-left: 75px;
  margin-right: 75px;
}
.form-inline-label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: normal;
}
.borderSimple {
  border: 1px solid #ccc;
  padding: 5px;
  background-color: #fff;
  border-radius: 5px;
}
.leftSpacing {
  margin-left: 2px;
}
.steps-radio-checkbox {
  float: left;
  margin-left: -20px;
  position: absolute;
  padding-right: 7px;
} 
.label-steps{
  font-weight: 400;
}
.alert-steps {
  padding: 10px;
}
.certify-steps{
  font-weight: normal;
}
.hr-listing {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}
.modal-content {
  width:100%;
}

.font-12 {
  font-size: 12px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .modal-dialog {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    min-height:calc(100% - (.5rem * 2));
    overflow-y: initial !important
  }
  
  .modal-body{
    max-height: 550px;
    overflow-y: auto;
  } 
  
  @media (min-width: 576px) {
    .modal-dialog {
      min-height:calc(100% - (1.75rem * 2));
    }
  }
 
}
.navbar-collapse {
  border-top: none;
  box-shadow: none;
}

.homepage_h1 {
  color: #fff;
  font-family: 'Droid Serif', Georgia, 'Times New Roman', Times, serif;
}

.aosDropdown {
  float: right;
  margin-bottom: 1rem;
}